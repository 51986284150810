// Base
import * as React from 'react'
import Parser from 'html-react-parser'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { styled } from '@mui/material/styles'

// Components
import { Button, Chip, Container, Stack, Toolbar, Typography } from '@mui/material'
import TrustedBy from '../TrustedBy'

// Assets
// import ApparelIcon from '../../assets/images/svg/apparel.inline.svg'
// import FabricIcon from '../../assets/images/svg/fabric.inline.svg'
// import YarnsIcon from '../../assets/images/svg/yarns.inline.svg'
// import TrimsIcon from '../../assets/images/svg/trims.inline.svg'
// import HomeTextilesIcon from '../../assets/images/svg/home-textiles.inline.svg'
import RightIcon from '../../assets/images/svg/right.inline.svg'

// Styles
import * as S from './Hero.styles'

const MiniChip = styled(Chip)(({ theme }) => ({
  '&&': {
    height: 24,
    '&:hover': {
      backgroundColor: theme.palette.silkyGreen.main,
    },
    '.MuiChip-label': {
      fontSize: '12px',
      color: '#ffffff',
    },
  },
}))

const Hero = ({ content }) => {
  const { t } = useTranslation()

  if (content?.type === 'basic') {
    return (
      <S.BasicHero sx={{ backgroundImage: `url('${content.image.publicURL}')` }}>
        <Container maxWidth='md' sx={{ py: 7 }}>
          <Toolbar />
          {content.title && (
            <Typography
              variant='h1'
              sx={{ color: 'bluishGrey.one', textShadow: (theme) => theme.textShadows[3], mb: 4 }}
            >
              {content.title}
            </Typography>
          )}
          {content.subtitle && (
            <Typography
              variant='h2'
              component='h1'
              sx={{ color: 'bluishGrey.one', textShadow: (theme) => theme.textShadows[3], mb: 4 }}
            >
              {content.subtitle}
            </Typography>
          )}
          {content.description && (
            <Typography
              variant='body1Bold'
              component='p'
              sx={{ color: 'bluishGrey.one', textShadow: (theme) => theme.textShadows[3], mb: 4 }}
            >
              {content.description}
            </Typography>
          )}
        </Container>
      </S.BasicHero>
    )
  }
  return (
    <S.FullHero>
      <Container maxWidth='md' sx={{ textAlign: 'center', py: 7 }}>
        <Toolbar />
        <Typography variant='h1' sx={{ color: 'bluishGrey.one', textShadow: (theme) => theme.textShadows[3], mb: 4 }}>
          {Parser(t('TITLE'))}
        </Typography>
        <Typography
          variant='body1Bold'
          component='p'
          sx={{ color: 'bluishGrey.one', textShadow: (theme) => theme.textShadows[3], mb: 4 }}
        >
          {Parser(t('SUBTITLE'))}
        </Typography>
        <Stack direction='row' justifyContent='center' sx={{ flexWrap: 'wrap', gap: 1, width: '100%', mb: 8 }}>
        <Button
          variant='contained'
          color='primary'
          href={`${process.env.GATSBY_CHINA === 'true' ? process.env.APP_CHINA_URL : process.env.APP_URL}/signup`}
          rel='noopener'
          size='large'
          // endIcon={<RightIcon />}
          sx={{ mb: 4 }}
        >
          {Parser(t('Start a project'))}
        </Button>
        <Button
          variant='outlined'
          color='white'
          href='https://demodesk.com/book/foursource-group-gmbh/foursource-sourcing'
          rel='noopener'
          size='large'
          // endIcon={<RightIcon />}
          sx={{ mb: 4 }}
        >
          {Parser(t('Book a demo'))}
        </Button>
        </Stack>
        {/* <Stack direction='row' justifyContent='center' sx={{ flexWrap: 'wrap', gap: 1, width: '100%', mb: 8 }}>
          <a href='/explore-network/?type=apparel'>
            <Chip icon={<ApparelIcon />} label='Apparel' color='silkyGreen' clickable />
          </a>
          <a href='/explore-network/?type=fabrics'>
            <Chip icon={<FabricIcon />} label='Fabrics' color='indigo' clickable />
          </a>
          <a href='/explore-network/?type=trims'>
            <Chip icon={<TrimsIcon />} label='Trims' color='darkBlue' clickable />
          </a>
          <a href='/explore-network/?type=yarns'>
            <Chip icon={<YarnsIcon />} label='Yarns' color='tourquise' clickable />
          </a>
          <a href='/explore-network/?type=hometextiles'>
            <Chip
              icon={<HomeTextilesIcon />}
              label='Home Textiles'
              color='mysticIris'
              clickable
              onDelete={() => {}}
              deleteIcon={<MiniChip label='New' color='silkyGreen' size='small' />}
            />
          </a>
        </Stack> */}
        <TrustedBy mode={'default'} disposition={'row'} show={true} />
      </Container>
    </S.FullHero>
  )
}

export default Hero
