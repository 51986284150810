import * as React from 'react'
import { graphql } from 'gatsby'

// Components
import Layout from '../components/layout'
import Seo from '../components/seo'
import Hero from '../components/Hero'
import Columns from '../components/Columns'
import CardList from '../components/CardList'
import TextList from '../components/TextList'
import Cta from '../components/Cta'

export const query = graphql`
  query ($slug: String!) {
    branchesJson(slug: { eq: $slug }) {
      title
      hero {
        type
        title
        image {
          publicURL
        }
      }
      columns1 {
        direction
        sizes
        mode
        illustration {
          alt
          src {
            publicURL
          }
        }
        text {
          title
          description
        }
      }
      columns2 {
        direction
        sizes
        mode
        illustration {
          alt
          src {
            publicURL
          }
        }
        text {
          title
          description
        }
      }
      cardlist {
        title
        component
        extraClasses
        items {
          photo {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, quality: 100, placeholder: BLURRED)
            }
          }
          name
          role
          phone
          mail
        }
      }
      columns3 {
        direction
        sizes
        mode
        illustration {
          alt
          src {
            publicURL
          }
        }
        text {
          title
          description
        }
      }
      textlist {
        title
        backgroundColor
        textColor
        items {
          title
          description
        }
      }
      cta {
        title
        subhead
        bg {
          image {
            publicURL
          }
        }
        buttons {
          text
          href
          component
          variant
          color
          target
          rel
        }
      }
    }
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

const BranchesTemplate = ({ data }) => {
  const branches = data.branchesJson

  return (
    <Layout>
      <Hero content={branches.hero} />
      <Columns content={branches.columns1} />
      <Columns content={branches.columns2} />
      <CardList content={branches.cardlist} />
      <Columns content={branches.columns3} />
      <TextList content={branches.textlist} />
      <Cta content={branches.cta} />
    </Layout>
  )
}

export const Head = ({ data, location }) => (
  <Seo
    title={data.branchesJson.title}
    description={data.branchesJson.columns1.text.description.substring(0, 167) + '...'}
    pathname={location.pathname}
  />
)

export default BranchesTemplate
