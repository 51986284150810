// Base
import * as React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

// Components
import { Paper, Link, Typography } from '@mui/material'

// Styles
import * as S from './StaffCard.styles'

const StaffCard = ({ content }) => {
  return (
    <S.GridItem>
      <Paper
        sx={{
          backgroundColor: 'background.default',
          boxShadow: '0px 8px 16px rgba(171, 190, 209, 0.4)',
          height: '100%',
          textAlign: 'left',
          borderRadius: 1.5,
          position: 'relative',
          overflow: 'hidden',
          color: 'bluishGrey.one',
        }}
      >
        {content?.photo &&
          (typeof content.photo === 'object' ? (
            <GatsbyImage image={getImage(content.photo)} alt={content.name} />
          ) : (
            <img src={content.photo} alt={content.name} />
          ))}

        <S.Overlay>
          {content.name && (
            <Typography variant='subhead' sx={{ color: 'inherit', mb: 1 }}>
              {content.name}
            </Typography>
          )}
          {content.role && (
            <Typography variant='body1Bold' sx={{ mb: 1 }}>
              {content.role}
            </Typography>
          )}
          {content.phone && (
            <Typography variant='body3'>
              <Link color='inherit' underline='hover' rel='noopener' href={`tel:${content.phone}`}>
                {content.phone}
              </Link>
            </Typography>
          )}
          {content.mail && (
            <Typography variant='body3'>
              <Link color='inherit' underline='hover' rel='noopener' href={`mailto:${content.mail}`}>
                {content.mail}
              </Link>
            </Typography>
          )}
        </S.Overlay>
      </Paper>
    </S.GridItem>
  )
}
export default StaffCard
