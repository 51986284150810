// Base
import * as React from 'react'

// Components
import { Box, Container, Stack, Typography } from '@mui/material'

const TextList = ({ content }) => {
  let itemsList = content.items
  let halfItemsList = Math.floor(itemsList.length / 2)
  let leftItemsList = itemsList.slice(0, halfItemsList)
  let rightItemsList = itemsList.slice(halfItemsList, itemsList.length)

  return (
    <Box sx={{ my: { xs: 6, md: 8 }, mx: 2 }}>
      <Container
        maxWidth='xl'
        sx={{
          textAlign: 'center',
          py: content?.backgroundColor && 8,
          borderRadius: 3,
          backgroundColor: content?.backgroundColor,
          color: content?.textColor,
        }}
      >
        <Container maxWidth='md'>
          {content?.title && (
            <Typography variant='h2' sx={{ mb: 6 }}>
              {content.title}
            </Typography>
          )}
        </Container>
        <Stack spacing={5} direction={{ xs: 'column', md: 'row' }}>
          <Box>
            <Stack spacing={5}>
              {leftItemsList.map((item, i) => (
                <Box key={i} sx={{ px: 8 }}>
                  <Typography variant='subhead' sx={{ color: content?.textColor || 'bluishGrey.seven' }}>
                    {item.title}
                  </Typography>
                  <Typography variant='body1' sx={{ color: content?.textColor || 'bluishGrey.seven' }}>
                    {item.description}
                  </Typography>
                </Box>
              ))}
            </Stack>
          </Box>
          <Box>
            <Stack spacing={5}>
              {rightItemsList.map((item, i) => (
                <Box key={i} sx={{ px: 8 }}>
                  <Typography variant='subhead' sx={{ color: content?.textColor || 'bluishGrey.seven' }}>
                    {item.title}
                  </Typography>
                  <Typography variant='body1' sx={{ color: content?.textColor || 'bluishGrey.seven' }}>
                    {item.description}
                  </Typography>
                </Box>
              ))}
            </Stack>
          </Box>
        </Stack>
      </Container>
    </Box>
  )
}
export default TextList
