import styled from 'styled-components'
import { Box } from '@mui/material'

// Assets
import background from '../../assets/images/hero/background.jpg'

export const Hero = styled(Box)`
  background-size: cover;
  position: relative;
`

export const FullHero = styled(Hero)`
  // background-image: radial-gradient(164.93% 48.4% at 47.87% 41.82%, #32a679 0%, #125037 100%);
  // background: radial-gradient(123.12% 87.72% at 31.67% 61.45%, #008092 0%, #004145 100%);
  background: url(${background});
  background-position: center;
  background-size: cover;
  padding-bottom: 90px;
  @media (min-width: 576px) {
    background-image: url(${background});
    background-size: cover;
    background-position: center;
  }
`
export const BasicHero = styled(Hero)`
  display: flex;
  align-items: center;
  background-position: center 0;
  @media (min-width: 576px) {
    min-height: 680px;
  }
  &:before {
    content: '';
    position: absolute;
    bottom: -56px;
    left: 0;
    width: 100%;
    height: 80px;
    background: #fff;
    z-index: 1;
    border-radius: 24px 24px 0 0;
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #353f5880;
  }
  .MuiContainer-root {
    position: relative;
    z-index: 1;
    text-align: center;
  }
`
