// Base
import * as React from 'react'

// Components
import { Box, Container, Typography } from '@mui/material'
import StaffCard from '../StaffCard'
import CtaButton from '../CtaButton'

// Styles
import * as S from './CardList.styles'

const Components = {
  staffcard: StaffCard,
}

const CardList = ({ content }) => {
  return (
    <Box>
      <Container disableGutters maxWidth='xl' sx={{ textAlign: 'center' }}>
        {content?.title && (
          <Typography variant='h2' sx={{ color: 'bluishGrey.ten', mb: 6 }}>
            {content.title}
          </Typography>
        )}
        {content?.component && (
          <S.Grid className={`row ${content.extraClasses || ''}`}>
            {content.items.map((item, i) => {
              if (typeof content.component === 'object') {
                return (
                  <React.Fragment key={i}>{React.cloneElement(content.component, { content: item })}</React.Fragment>
                )
              } else {
                return React.createElement(Components[content.component], { content: item, key: i })
              }
            })}
          </S.Grid>
        )}
        {content?.button && <CtaButton content={content.button} />}
      </Container>
    </Box>
  )
}
export default CardList
